body {
  background: #1e1e1e;
  color: #e0e0e0;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 6rem;
  text-align: center;
  margin-bottom: 2rem;


}

.note-input {
  display: flex;
  margin-bottom: 2rem;
  background-color: #00000000;
}

textarea {
  flex-grow: 1;
  height: 100px;
  padding: 0.5rem;
  border: none;
  border-radius: 8px 0 0 8px;
  resize: none;
  font-size: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button {
  padding: 0.5rem 1rem;
  background: linear-gradient(45deg, #8a2be2, #9370db);
  color: white;
  border: none;
  border-radius: 8px 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

button:hover {
  background: linear-gradient(45deg, #8a2be2, #9370db);
  transform: translateY(-1px);
}

.note-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.note-item {
  background: #3a3a3a90;
  color: #e0e0e0;
  padding: 1rem;
  border: 1px solid #96959520;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.512);
  transition: all 0.3s ease;
  position: relative;
  margin-left: 20px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.note-item:hover {
  transform: translateY(-2.5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.note-content {
  cursor: pointer;
  word-wrap: break-word;
  padding: 0.5rem;
  min-height: 1.5rem;
}

.note-content.completed {
  text-decoration: line-through;
  color: #888;
}

.add-child {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-item .note-item {
  margin-top: 10px;
}

.note-edit {
  width: 100%;
  padding-right: 20px; /* Add padding to account for buttons */
}

.note-edit input {
  width: calc(100% - 2rem); /* Subtract padding from width */
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #44444400;
  border-radius: 4px;
  background-color: #33333300;
  color: #e0e0e0;
  box-sizing: border-box; /* Include padding in width calculation */
}

.delete-note {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  color: #888;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.delete-note:hover {
  background-color: #444;
  color: #fff;
}

.new-page-button {
  display: flex;
  align-items: center;
  background: #2a2a2a;
  border: 2px solid #8716f0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #ffffff;
}

.new-page-button:hover {
  background: #802be2;
  color: #ffffff;
}

.new-page-button .icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.new-page-button span {
  font-size: 1rem;
}

.toggle-complete {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: none;
  border: none;
  color: #888;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.toggle-complete:hover {
  background-color: #444;
  color: #fff;
}

.top-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.menu-toggle, .save-button, .load-button {
  background: #8716f000;
  color: white;
  
}

.menu-toggle:hover, .save-button:hover, .load-button:hover {
  background: #272727;
}

.menu-toggle {
  position: absolute;
  left: 50px;
  top: 50px;
  background: #8716f09a;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
}

.menu-toggle:hover {
  background: #e32bed00;
  border: 1px solid #8716f0;
}

.collapsible-menu {
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 0;
}

.collapsible-menu.open {
  display: flex;
  max-height: 200px; /* Adjust this value based on your needs */
}

.save-button, .load-button {
  background: #be4ecd;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 1rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 10% 15%;
}

.save-button.local {
  background: linear-gradient(45deg, #8a2be2, #9370db);
}

.save-button.local:hover {
  background: linear-gradient(45deg, #9370db, #8a2be2);
}

.save-button.cloud, .load-button.cloud {
  background: linear-gradient(45deg, #8a2be2, #9370db);
}

.save-button.cloud:hover, .load-button.cloud:hover {
  background: linear-gradient(45deg, #9370db, #8a2be2);
}

.cloud-save-id {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #8a2be2;
  
}

.side-drawer {
  background-color: #2a2a2a;
  color: #e0e0e0;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  z-index: 1000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateX(-100%);
}

.side-drawer.open {
  transform: translateX(0);
}

.main-content {
  transition: margin-left 0.3s ease-out;
}

@media (min-width: 769px) {
  .side-drawer.open + .main-content {
    margin-left: 250px;
  }
}

@media (max-width: 768px) {
  .side-drawer.open + .main-content {
    margin-left: 0;
  }
}

.center {
  text-align: center;
}
/* Remove these rules as they're no longer needed */
/* .main-content {
  transform: translateX(0);
} */

/* body {
  overflow-x: hidden;
} */

.info-button {
  position: absolute;
  top: 50px; 
  right: 50px;
  background: var(--button-gradient);
  color: var(--text-primary);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
}

.info-button:hover {
  background: var(--button-hover-gradient);
  transform: scale(1.1);
  color: hsl(270, 100%, 62%);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.popup-content {
  background-color: #2a2a2a;
  padding: 2rem;
  border-radius: 8px;
  width: 600px;
  height: 400px;
  color: #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.popup-content h2 {
  color: #8716f0;
  margin-bottom: 1rem;
}

.popup-content p {
  margin-bottom: 1rem;
  flex-grow: 1;
}

.close-popup {
  background: linear-gradient(45deg, #8a2be2, #9370db);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-end;
}

.close-popup:hover {
  background: linear-gradient(45deg, #9370db, #8a2be2);
}

/* ... (rest of the styles remain the same) */
