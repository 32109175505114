.passkey-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002;
}

.passkey-dialog {
  background-color: #2a2a2a;
  padding: 2rem;
  border-radius: 8px;
  width: 400px;
  color: #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passkey-dialog h2 {
  color: #8716f0;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.passkey-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #8716f0;
  border-radius: 4px;
  background-color: #3a3a3a;
  color: #e0e0e0;
  margin-bottom: 1rem;
}

.passkey-dialog-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cancel-button, .verify-button, .save-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.cancel-button {
  background-color: #4a4a4a;
  color: #e0e0e0;
}

.verify-button, .save-button {
  background: linear-gradient(45deg, #8a2be2, #9370db);
  color: white;
}

.cancel-button:hover {
  background-color: #5a5a5a;
}

.verify-button:hover, .save-button:hover {
  background: linear-gradient(45deg, #9370db, #8a2be2);
}