.side-drawer {
  background-color: #2a2a2a;
  color: #e0e0e0;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 250px;
  height: 100vh;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  z-index: 1002; /* Increased z-index to be above the menu button */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateX(-100%);
  overflow-y: auto;
}

.side-drawer.open {
  transform: translateX(0);
}

.menu-button {
  background: transparent;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.menu-button:hover {
  background: #3a3a3a;
}

.submenu-container {
  display: flex;
  flex-direction: column;
}

.submenu {
  background-color: #3a3a3a;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
  margin-left: 10px;
}

.submenu-button {
  background: transparent;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
}

.submenu-button:hover {
  background: #4a4a4a;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .side-drawer {
    width: 70%;
    max-width: 250px;
  }

  body {
    overflow-x: hidden;
  }

  /* Remove or comment out these styles */
  /*
  .main-content {
    transition: transform 0.3s ease-out;
  }

  .side-drawer.open + .main-content {
    transform: translateX(0);
  }
  */
}

/* New styles for fading menu and top buttons */
.side-drawer.open ~ .main-content .menu-toggle,
.side-drawer.open ~ .main-content .top-buttons,
.side-drawer.open ~ .main-content .info-button {
  opacity: 0.5; /* Change to 0.5 to make buttons visible but faded */
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}

/* Ensure buttons remain in place when menu is open */
.side-drawer.open ~ .main-content {
  position: static;
}

/* Keep the menu toggle visible */
.side-drawer.open ~ .main-content .menu-toggle {
  opacity: 1;
  pointer-events: auto;
}