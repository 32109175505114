* {
  font-family: 'Roboto', sans-serif;
}

.install-prompt {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2a2a2a;
  color: #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.install-prompt p {
  margin-bottom: 10px;
  text-align: center; /* Center the text */
  width: 100%; /* Ensure the paragraph takes full width */
}

.install-prompt button {
  margin: 5px;
  padding: 8px 16px;
  background: linear-gradient(45deg, #8a2be2, #9370db);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto; /* Remove fixed width if any */
  max-width: none; /* Remove max-width if any */
}

.install-prompt button:hover {
  background: linear-gradient(45deg, #9370db, #8a2be2);
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%; /* Ensure the container takes full width */
}

.prompt-button {
  flex: 1;
  padding: 10px 0;
  margin: 0 5px;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%; /* Ensure buttons take full width of their flex container */
  max-width: 200px; /* Increased from 150px to 200px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* If you want to ensure the text doesn't wrap, add this: */
.prompt-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}